import { ChangeEvent, FC, memo } from "react";

type Props = {
  name: string;
  value: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void | undefined;
};

/** テキストエリアのコンポーネント */
export const TextArea: FC<Props> = memo((props) => {
  const { name, value, onChange } = props;
  return (
    <>
      <textarea
        onChange={onChange}
        value={value}
        name={name}
        className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"
      ></textarea>
    </>
  );
});
