import { atom } from "recoil";

type modalInfo = {
  isOpen: boolean;
};

export const deleteModalInfoState = atom<modalInfo>({
  key: "deleteModalInfoState",
  default: {
    isOpen: false,
  }
});