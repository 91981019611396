import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import Modal from "react-modal";
import { ChangeEvent, FC, memo, SetStateAction, useEffect, useState } from "react";
import { BookInputForm } from "../../organisms/BookInputForm";
import { BookControlForm } from "../../../types/form/BookControlForm";
import { editModalInfoState } from "../../../store/modal/editModalInfoState";
import { PrimaryButton } from "../../atoms/PrimaryButton";
import { PageTitle } from "../../atoms/PageTitle";
import { useUpdateBook } from "../../../hooks/useUpdateBook";
import { usersState } from "../../../store/usersState";
import { SecondaryButton } from "../../atoms/SecondaryButton";
import { ErrorMessage } from "../../atoms/ErrorMessage";

type Props = {
  updateTarget: BookControlForm;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    width: "40%",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
  },
};

export const BookEditModal: FC<Props> = memo((props) => {
  const user = useRecoilValue(usersState);
  const [modalInfo, setModalInfo] = useRecoilState(editModalInfoState);
  const setEditModalInfo = useSetRecoilState(editModalInfoState);
  const { updateBook, errorMessageList, responseStatus } = useUpdateBook();
  const { updateTarget } = props;
  const [updateFormData, setUpdateFormData] = useState<BookControlForm>({
    id: 0,
    isbnCode: "",
    bookName: "",
    authorName: "",
    publisherName: "",
    publishDate: "",
  });
  const getYYYYMMDD = (targetDate: string) => `${targetDate.slice(0, 4)}/${targetDate.slice(5, 7)}/${targetDate.slice(8, 10)}`;

  useEffect(() => {
    if (updateTarget.publishDate) updateTarget.publishDate = getYYYYMMDD(updateTarget.publishDate);
    setUpdateFormData(updateTarget);
  }, [updateTarget]);

  Modal.setAppElement("#root");

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUpdateFormData({ ...updateFormData, [name]: value });
  };

  const onChangeDate = (e: SetStateAction<Date>) => {
    const date = new Date(e.toString());
    setUpdateFormData({ ...updateFormData, "publishDate": date.getFullYear().toString() + "/" + ('00' + (date.getMonth() + 1)).slice(-2) + "/" + ('00' + date.getDate()).slice(-2) });
  };

  // 更新ボタン押下
  const editData = () => {
    updateBook(updateFormData, user.userId, user.token);
  };

  const closeModal = () => {
    setModalInfo({ isOpen: false });
  };

  // キャンセルボタン押下
  const onClickCancel = () => {
    setEditModalInfo({ isOpen: false });
  };

  return (
    <>
      <Modal
        isOpen={modalInfo.isOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="font-body">
          <PageTitle>書籍情報編集</PageTitle>
          {
          // 入力エラーメッセージ表示
          errorMessageList !== undefined && errorMessageList.length ? (
            <ErrorMessage>
              {errorMessageList.map((errorMessage) => {
                return (
                  <>
                    {errorMessage}
                    <br />
                  </>
                );
              })}
            </ErrorMessage>
          ) : (
            ""
          )
        }
          <BookInputForm formData={updateFormData} onChangeText={onChangeText} onChangeDate={onChangeDate} />
          <div className="flex justify-end items-center">
            <div className="mr-2 inline-block">
              <PrimaryButton onClick={editData}>更新</PrimaryButton>
            </div>
            <div className="ml-2 inline-block">
              <SecondaryButton onClick={onClickCancel}>キャンセル</SecondaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});
