import { FC, memo } from "react";
import { BookControlForm } from "../../types/form/BookControlForm";
import { FormLabel } from "../atoms/FormLabel";

type Props = {
  formData: BookControlForm;
};

/** 書籍検索結果のフォーム */
export const BookSearchInfoForm: FC<Props> = memo((props) => {
  const { formData } = props;

  return (
    <>
      <div className="flex flex-col text-left">
        <FormLabel labelText={"バーコード番号: " + formData.isbnCode} />
        <FormLabel labelText={"書籍名: " + formData.bookName} />
        <FormLabel labelText={"出版社名: " + formData.publisherName} />
        <FormLabel labelText={"著者名: " + formData.authorName} />
        <FormLabel labelText={"出版日: " + formData.publishDate} />
      </div>
    </>
  );
});
