import { ChangeEvent, FC, memo } from "react";
import { FormLabel } from "../atoms/FormLabel";
import { RequiredLabel } from "../atoms/RequiredLabel";
import { TextArea } from "../atoms/TextArea";

type Props = {
  labelText: string;
  name: string;
  onChangeTextArea: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  value: string;
  required: boolean;
};

export const TextAreaForm: FC<Props> = memo((props) => {
  const { labelText, name, onChangeTextArea, value, required } = props;

  return (
    <>
      <div className="mb-6">
        <FormLabel labelText={labelText} />
        <div className="col mb-0">{required && <RequiredLabel labelText={"必須"} />}</div>
        <TextArea name={name} value={value} onChange={onChangeTextArea} />
      </div>
    </>
  );
});
