import axios from "axios";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { BookControlForm } from "../types/form/BookControlForm";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL || "";
const UPDATE_BOOK_API_URL = "/api/bookUpdate";

export const useUpdateBook = () => {
  const [errorMessageList, setErrorMessageList] = useState<Array<string>>([]);
  const [responseStatus, setResponseStatus] = useState<number>(0);

  const updateBook = useCallback(
    async (data: BookControlForm, userId: string, token: string) => {
      axios
        .post(
          BASE_API_URL + UPDATE_BOOK_API_URL,
          {
            id: data.id,
            isbnCode: data.isbnCode,
            bookName: data.bookName,
            authorName: data.authorName,
            publisherName: data.publisherName,
            publishDate: data.publishDate,
            userId: userId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response: any) => {
          if (response.status === 200) {
            let status = response.status;
            setResponseStatus(status);
            setErrorMessageList([]);

            const toastMessage = new Promise<void>((resolve) => {
              toast.success("書籍を更新しました", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000,
              });
              resolve();
            });
            // toast表示後2秒後にリフレッシュ
            toastMessage.then(() => {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
          }
        })
        .catch((error) => {
          let status = error.status;
          setResponseStatus(status);
          setErrorMessageList(error.response.data.errorMessageList);
        });
    },
    [errorMessageList, responseStatus]
  );

  return { updateBook, errorMessageList, responseStatus };
};
