import { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export const BarcodeButton: FC<Props> = memo((props) => {
  const { children, onClick } = props;
  return (
    <button
      type="submit"
      className="bg-geexgreen-200 hover:bg-geexgreen-100 text-white rounded px-4 py-2 mb-4"
      onClick={onClick}
    >
      {children}
    </button>
  );
});
