import { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

/** メッセージボックス */
export const InfoMessage: FC<Props> = memo((props) => {
  const { children } = props;
  return (
    <>
      <div className="bg-pink-100 border border-pink-500 text-pink-700 px-4 py-2 rounded my-2" role="alert">
        <span className="block sm:inline">{children}</span>
      </div>
    </>
  );
});
