import { MouseEvent, ChangeEvent, FC, memo, SetStateAction, useCallback, useEffect, useState } from "react";
import { FcCalendarMemo } from "../atoms/FcCalendarMemo";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { calendarModalInfoState } from "../../store/modal/calendarModalInfoState";
import { BookSearchPanelForm } from "../../types/form/BookSearchPanelForm";
import { PrimaryButton } from "../atoms/PrimaryButton";
import { SecondaryButton } from "../atoms/SecondaryButton";
import { SelectList } from "../atoms/SelectList";
import { TextBox } from "../atoms/TextBox";
import { BookCalendarModal } from "../pages/modal/BookCalendarModal";
import { EmployeeInfo } from "../../types/api/EmployeeInfo";
import { usersState } from "../../store/usersState";
import { SelectItem } from "../../types/SelectItem";
import { TertiaryButton } from "../atoms/TertiaryButton";

type Props = {
  employeeList: Array<EmployeeInfo>;
  fetchBookList: (token: string, params?: BookSearchPanelForm) => void;
};

export const BookSearchPanel: FC<Props> = memo((props) => {
  const { employeeList, fetchBookList } = props;

  // アコーディオン
  const [openFlag, setOpenFlag] = useState<boolean>(false);
  const [arrowClass, setArrowClass] = useState<string>("");
  const [dispClass, setDispClass] = useState<string>("max-h-0 invisible opacity-0");

  // 検索パネル
  const initialPanelData = {
    bookName: "",
    authorName: "",
    lendStatus: "0",
    lendEmployeeNo: "",
    lendDateFrom: "",
    lendDateTo: "",
  };
  const [panelData, setPanelData] = useState<BookSearchPanelForm>(initialPanelData);

  // 貸出状態プルダウン
  const initialLendStatusList = [
    { value: "1", label: "貸出可能" },
    { value: "2", label: "貸出中" },
  ];
  const [lendStatusList] = useState<Array<SelectItem>>(initialLendStatusList);

  // 貸出中フラグ(貸出状態 -> [全て("0") or 貸出中("2"):true, 貸出可能("1"):false])
  const [lendingFlag, setLendingFlag] = useState<boolean>(true);
  useEffect(() => {
    if (!lendingFlag) {
      setPanelData((prevPanelData) => ({ ...prevPanelData, 
        lendEmployeeNo: initialPanelData.lendEmployeeNo, 
        lendDateFrom: initialPanelData.lendDateFrom,
        lendDateTo: initialPanelData.lendDateTo
      }));
    }
  }, [lendingFlag]);

  // 貸出者プルダウン
  const [selectItemList, setSelectItemList] = useState<Array<SelectItem>>([]);
  useEffect(() => {
    const tempSelectItemList: Array<SelectItem> = [];
    employeeList.map((data) => {
      tempSelectItemList.push({ value: data.employeeNo, label: data.employeeName });
    });
    setSelectItemList(tempSelectItemList);
  }, [employeeList]);

  // アコーディオンを変更
  const changeAccordion = () => {
    if (openFlag) {
      // アコーディオン閉じる
      setDispClass("max-h-0 invisible opacity-0 transition-all duration-500");
      setArrowClass("duration-500");
    } else {
      // アコーディオン開く
      setDispClass("max-h-96 transition-all duration-500");
      // ▼を反対向きにする
      setArrowClass("rotate-180 duration-500");
    }
    setOpenFlag(!openFlag);
  };

  // 検索パネルを変更
  const onChangePanel = useCallback((e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setPanelData((prevPanelData) => ({ ...prevPanelData, [name]: value }));

    if (name === "lendStatus"){
      setLendingFlag(!(value === "1"));
    }
  }, []);

  // 貸出日カレンダー押下
  const setCalendarModalInfo = useSetRecoilState(calendarModalInfoState);
  const onClickCalendarButton = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    const targetModalId = e.currentTarget.getAttribute("data-targetmodalid") || "";
    setCalendarModalInfo((prevModalInfo) => ({...prevModalInfo, isOpenId: targetModalId}));
    return;
  }, []);

  // 貸出日カレンダーを変更
  const getDateToStr = (date: Date) => `${date.getFullYear()}/${("00" + (date.getMonth() + 1)).slice(-2)}/${("00" + date.getDate()).slice(-2)}`;
  const onChangeDateFrom = useCallback((e: SetStateAction<Date>) => {
    const date = new Date(e.toString());
    setPanelData((prevPanelData) => ({...prevPanelData, lendDateFrom: getDateToStr(date) }));
  }, []);
  const onChangeDateTo = useCallback((e: SetStateAction<Date>) => {
    const date = new Date(e.toString());
    setPanelData((prevPanelData) => ({...prevPanelData, lendDateTo: getDateToStr(date) }));
  }, []);

  // クリアボタン押下
  const clearPanel = useCallback(() => {
    setPanelData(initialPanelData);
    setLendingFlag(true);
  }, []);

  // 検索ボタン押下
  const user = useRecoilValue(usersState);
  const searchPanel = useCallback(() => {
    fetchBookList(user.token, panelData);
  }, [panelData]);

  return (
    <>
      <div className="mb-2">
        <div className="p-2 md:p-4 border-gray-200 border rounded-lg mx-auto">
          <div className="flex justify-start items-center p-3">
            <div className="px-2">書籍名：</div>
            <div className="px-4 flex-grow">
              <TextBox name={"bookName"} value={panelData.bookName} onChange={onChangePanel} />
            </div>
            <div className="px-2">著者名：</div>
            <div className="px-4 flex-grow">
              <TextBox name={"authorName"} value={panelData.authorName} onChange={onChangePanel} />
            </div>
          </div>
          <p className="relative text-white border border-gray-200 cursor-pointer bg-geexgreen-200 mx-3 rounded" onClick={changeAccordion}>
            <span className="block w-full px-1 py-2 text-center">
              <span className="mr-3">詳細検索</span>
              <span className={`inline-block ${arrowClass}`}>▼</span>
            </span>
          </p>
          <div className={dispClass}>
            <div className="flex justify-start items-center p-3">
              <div className="px-2">貸出状態：</div>
              <div className="px-4">
                <SelectList name={"lendStatus"} value={panelData.lendStatus} defaultSelectedLabel={"全て"}  defaultSelectedValue={"0"} selectItem={lendStatusList} onChangeItem={onChangePanel} />
              </div>
              <div className="px-2">貸出者：</div>
              <div className="px-4 max-w-xs">
                <SelectList name={"lendEmployeeNo"} value={panelData.lendEmployeeNo} isDisabled={!lendingFlag} defaultSelectedLabel={"全て"} selectItem={selectItemList} onChangeItem={onChangePanel} />
              </div>
            </div>
            <div className="flex justify-start items-center p-3">
              <div className="px-2">貸出日：</div>
              <div className="px-4">
                <TextBox name={"lendDateFrom"} value={panelData.lendDateFrom} isDisabled={!lendingFlag} onChange={onChangePanel} />
              </div>
              <div className="px-2 flex items-center">
                <button className="text-4xl" type="button" disabled={!lendingFlag} onClick={onClickCalendarButton} data-targetmodalid="lendDateFromModal">
                  <FcCalendarMemo />
                </button>
                <BookCalendarModal id={"lendDateFromModal"} calendarTarget={panelData.lendDateFrom} onChangeDate={onChangeDateFrom} />
              </div>
              <div className="px-4">～</div>
              <div className="px-4">
                <TextBox name={"lendDateTo"} value={panelData.lendDateTo} isDisabled={!lendingFlag} onChange={onChangePanel} />
              </div>
              <div className="px-2 flex items-center">
                <button className="text-4xl" type="button" disabled={!lendingFlag} onClick={onClickCalendarButton} data-targetmodalid="lendDateToModal">
                  <FcCalendarMemo />
                </button>
                <BookCalendarModal id={"lendDateToModal"} calendarTarget={panelData.lendDateTo} onChangeDate={onChangeDateTo} />
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center p-3">
            <div className="mr-2 inline-block">
              <TertiaryButton onClick={clearPanel}>クリア</TertiaryButton>
            </div>
            <div className="mr-2 inline-block">
              <PrimaryButton onClick={searchPanel}>検索</PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
