import { MouseEvent, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { useFetchBookList } from "../../hooks/useFetchBookList";
import { usersState } from "../../store/usersState";
import { deleteModalInfoState } from "../../store/modal/deleteModalInfoState";
import { editModalInfoState } from "../../store/modal/editModalInfoState";
import { lendAndReturnModalInfoState } from "../../store/modal/lendAndReturnModalInfoState";
import { DefaultTemplete } from "../templetes/DefaultTemplete";
import { BookDeleteConfirmModal } from "./modal/BookDeleteConfirmModal";
import { BookEditModal } from "./modal/BookEditModal";
import { BookControlForm } from "../../types/form/BookControlForm";
import { BookLendAndReturnModal } from "./modal/BookLendAndReturnModal";
import { BookLendReturnForm } from "../../types/form/BookLendReturnForm";
import { BookInfo } from "../../types/api/BookInfo";
import { toast } from "react-toastify";
import { FcDownload, FcEditImage, FcEmptyTrash } from "react-icons/fc";
import { BookSearchPanel } from "../organisms/BookSearchPanel";
import { useFetchEmployeeList } from "../../hooks/useFetchEmployeeList";
import { ErrorMessage } from "../atoms/ErrorMessage";
import { InfoMessage } from "../atoms/InfoMessage";
import { StatusIcon } from "../atoms/StatusIcon";
import { BookLendHistoryModal } from "./modal/BookLendHistoryModal";
import { lendHistoryModalInfoState } from "../../store/modal/lendHistoryModalInfoState";

/** 書籍一覧画面 */
export const BookList = memo(() => {
  const navigate = useNavigate();
  const user = useRecoilValue(usersState);
  const [deleteModalInfo, setDeleteModalInfo] =
    useRecoilState(deleteModalInfoState);
  const [editModalInfo, setEditModalInfo] = useRecoilState(editModalInfoState);
  const [lendAndReturnModalInfo, setLendAndReturnModalInfo] = useRecoilState(
    lendAndReturnModalInfoState
  );
  const [lendHistoryModalInfo, setLendHistoryModalInfo] = useRecoilState(
    lendHistoryModalInfoState
  );
  const { fetchEmployeeList, employeeList } = useFetchEmployeeList();
  const {
    fetchBookList,
    bookList,
    fetchCompleteFlag,
    errorMessageList: searchErrorMessageList,
  } = useFetchBookList();
  const [deleteTarget, setDeleteTarget] = useState<BookControlForm>({
    id: 0,
    isbnCode: "",
    bookName: "",
    authorName: "",
    publisherName: "",
    publishDate: "",
  });
  const [updateTarget, setUpdateTarget] = useState<BookControlForm>({
    id: 0,
    isbnCode: "",
    bookName: "",
    authorName: "",
    publisherName: "",
    publishDate: "",
  });

  const [lendReturnTarget, setLendReturnTarget] = useState<BookLendReturnForm>({
    id: 0,
    bookName: "",
    bookId: 0,
    lendEmployeeNo: "",
    lendDate: "",
    returnDate: "",
    remarks: "",
    employeeFullName: "",
  });

  const [historyTarget, setHistoryTarget] = useState<BookControlForm>({
    id: 0,
    isbnCode: "",
    bookName: "",
    authorName: "",
    publisherName: "",
    publishDate: "",
  });

  //　貸出返却フラグ。貸出している:true/貸出していない:false
  const [lendFlag, setLendFlag] = useState<boolean>(false);

  // 初期表示
  useEffect(() => {
    fetchEmployeeList(user.token);
    fetchBookList(user.token);
  }, []);

  // 選択している書籍(未選択:-1, 選択:該当の行番号(index))
  const [selectedRowIndex, setSelectedRowIndex] = useState(-1);
  const selectedTrStyle = { background: "beige" };
  useEffect(() => {
    if (
      !(
        deleteModalInfo.isOpen ||
        editModalInfo.isOpen ||
        lendAndReturnModalInfo.isOpen ||
        lendHistoryModalInfo.isOpen
      )
    ) {
      setSelectedRowIndex(-1);
    }
  }, [deleteModalInfo, editModalInfo, lendAndReturnModalInfo,lendHistoryModalInfo, bookList]);

  // 各書籍のClickイベントハンドラWrap
  const onClickWrap = (e: MouseEvent<any>, handler: () => any) => {
    const targetTrIndex = e.currentTarget.closest("tr")?.sectionRowIndex;
    if (targetTrIndex !== undefined && targetTrIndex >= 0) {
      setSelectedRowIndex(targetTrIndex);
    }
    handler();
  };

  // 編集押下
  const onClickEditButton = (data: BookControlForm) => {
    if (data.id === null || data.id === undefined) {
      return;
    }
    if (data.publishDate === null || data.publishDate === undefined) {
      data.publishDate = "";
    }
    setUpdateTarget(data);
    setEditModalInfo({ isOpen: true });
    return;
  };

  // 削除押下
  const onClickDeleteButton = (data: BookInfo) => {
    if (data.id === null || data.id === undefined) {
      return;
    }
    if (data.lendDate !== undefined) {
      toast.warning("貸出中の為、削除できません", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;
    }
    setDeleteModalInfo({ isOpen: true });
    setDeleteTarget(data);
    return;
  };

  // 貸出リンク押下
  const onClickLendLink = (data: BookInfo) => {
    if (data.id === null || data.id === undefined) {
      return;
    }
    data.lendDate === undefined ? setLendFlag(false) : setLendFlag(true);
    setLendReturnTarget({ ...data, id: data.lendId, bookId: data.id });
    setLendAndReturnModalInfo({ isOpen: true });
    return;
  };

  const onClickRegistButton = () => {
    navigate("/bookRegist", { state: { rst: false } });
  };

    // 貸出履歴リンク押下
    const onClickLendHistoryLink = (data: BookInfo) => {
      setLendHistoryModalInfo({ isOpen: true });
      setHistoryTarget(data);
      return;
    };

  return (
    <>
      <DefaultTemplete title={"書籍一覧"}>
        {searchErrorMessageList !== undefined &&
        searchErrorMessageList.length ? (
          <ErrorMessage>
            {searchErrorMessageList.map((errorMessage) => {
              return (
                <>
                  {errorMessage}
                  <br />
                </>
              );
            })}
          </ErrorMessage>
        ) : (
          ""
        )}
        <div className="max-w-full">
          <BookSearchPanel
            employeeList={employeeList}
            fetchBookList={fetchBookList}
          />
          <div className="mb-5 text-right">
            <button
              onClick={onClickRegistButton}
              className="focus:ring-2 focus:ring-offset-2 focus:ring-indigo-600 mt-4 sm:mt-0 inline-flex items-start justify-start px-3 py-3 bg-indigo-700 hover:bg-indigo-600 focus:outline-none rounded"
            >
              <p className="text-sm font-medium leading-none text-white">
                新規追加
              </p>
            </button>
          </div>

          {fetchCompleteFlag ? (
            bookList.length === 0 ? (
              <InfoMessage>検索条件に該当する書籍は存在しません。</InfoMessage>
            ) : (
              <table className="table-fixed break-all w-full">
                <thead className="">
                  <tr className="bg-gray-200 text-gray-600">
                    <th className="px-5 py-3 w-4/12 lg:w-5/12 text-center">
                      書籍名
                    </th>
                    <th className="px-5 py-3 w-3/12 text-center">著者名</th>
                    <th className="px-5 py-3 w-3/12 lg:w-2/12 text-center">
                      貸出者
                    </th>
                    <th className="px-5 py-3 w-2/12 lg:w-2/12 text-center">
                      管理
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bookList.map((data, index) => {
                    return (
                      <tr
                        key={index}
                        className="border-b border-gray-200 hover:bg-gray-100 opacity-75"
                        style={
                          index === selectedRowIndex ? selectedTrStyle : {}
                        }
                      >
                        <td className="px-5 py-5">
                          <div className="flex justify-start items-center">
                            <p className="text-xs">
                              バーコード番号:{data.isbnCode}
                            </p>
                            {data.duplicateBookNum > 0 ? (
                              <StatusIcon>
                                {data.duplicateBookNum}冊目
                              </StatusIcon>
                            ) : (
                              ""
                            )}
                          </div>
                          <p className="text-base">{data.bookName}</p>
                        </td>
                        <td className="px-5 py-5 text-sm">{data.authorName}</td>
                        <td className="px-2 py-2 text-sm text-center">
                          <div
                            className="text-blue-500 cursor-pointer"
                            onClick={(e) =>
                              onClickWrap(e, () => onClickLendLink(data))
                            }
                          >
                            {data.employeeFullName === undefined ? (
                              <div
                                className="flex justify-center text-base text-gray-400"
                                title="貸し出す"
                              >
                                <div className="my-1">
                                  <FcDownload />
                                </div>
                                貸出
                              </div>
                            ) : (
                              <div className="underline">
                                {data.employeeFullName}
                              </div>
                            )}
                          </div>
                        </td>
                        <td className="px-2 py-2 text-lg">
                          <div className="flex items-center justify-center">
                            <div className="w-5 mr-2 ml-2 transform hover:text-purple-500 hover:scale-110">
                              <button
                                className="p-0 border-none"
                                title="編集"
                                type="button"
                                data-modal-toggle="popup-modal"
                                onClick={(e) =>
                                  onClickWrap(e, () => onClickEditButton(data))
                                }
                              >
                                <FcEditImage />
                              </button>
                            </div>
                            <div className="w-5 mr-2 ml-2 transform hover:text-purple-500 hover:scale-110">
                              <button
                                className="p-0 border-none"
                                title="削除"
                                type="button"
                                data-modal-toggle="popup-modal"
                                onClick={(e) =>
                                  onClickWrap(e, () =>
                                    onClickDeleteButton(data)
                                  )
                                }
                              >
                                <FcEmptyTrash />
                              </button>
                            </div>
                            <div className="w-7 mr-2 ml-2 underline transform hover:text-purple-500 text-sm text-blue-500 cursor-pointer"
                                onClick={(e) =>
                                  onClickWrap(e, () => onClickLendHistoryLink(data))
                                }
                              >
                                履歴
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )
          ) : (
            ""
          )}
          <BookLendAndReturnModal
            lendReturnTarget={lendReturnTarget}
            lendFlag={lendFlag}
            employeeList={employeeList}
          />
          <BookDeleteConfirmModal deleteTarget={deleteTarget} />
          <BookEditModal updateTarget={updateTarget} />
          <BookLendHistoryModal historyTarget={historyTarget}/>
        </div>
      </DefaultTemplete>
    </>
  );
});
