import axios from "axios";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { BookLendReturnForm } from "../types/form/BookLendReturnForm";

export const useRegistLendInfo = () => {
  const BASE_API_URL = process.env.REACT_APP_API_BASE_URL || "";
  const REGIST_LENDINFO_API_URL = "/api/registLendInfo";

  const [errorMessageList, setErrorMessageList] = useState<Array<string>>([]);
  const [responseStatus, setResponseStatus] = useState<number>(0);

  const registLendInfo = useCallback(
    async (data: BookLendReturnForm, userId: string, token: string) => {
      axios
        .post(
          BASE_API_URL + REGIST_LENDINFO_API_URL,
          {
            bookId: data.bookId,
            lendDate: data.lendDate,
            lendEmployeeNo: data.lendEmployeeNo,
            remarks: data.remarks,
            userId: userId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response: any) => {
          if (response.status === 201) {
            let status = response.status;
            setResponseStatus(status);
            setErrorMessageList([]);
            const toastMessage = new Promise<void>((resolve) => {
              toast.success("貸出登録しました", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000,
              });
              resolve();
            });

            toastMessage.then(() => {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
          }
        })
        .catch((error) => {
          let status = error.status;
          setResponseStatus(status);
          setErrorMessageList(error.response.data.errorMessageList);
        });
    },
    [errorMessageList, responseStatus]
  );
  return { registLendInfo,errorMessageList };
};
