import { FC } from "react";

type Props = {
  labelText: string;
};
export const RequiredLabel: FC<Props> = (props) => {
  const { labelText } = props;

  return (
    <>
      <label className="mx-auto text-sm font-medium text-red-600 border border-red-600 rounded-lg pl-1 pr-1 align-top">
        {labelText}
      </label>
    </>
  );
};
