import { ChangeEvent, FC, memo, SetStateAction } from "react";
import { BookControlForm } from "../../types/form/BookControlForm";
import { CalendarTextForm } from "../molecules/CalendarTextForm";
import { TextForm } from "../molecules/TextForm";

type Props = {
  formData: BookControlForm;
  onChangeText: (e: ChangeEvent<HTMLInputElement>) => void;
  onChangeDate?: (e: SetStateAction<Date>) => void;
};

/** 書籍情報のフォーム */
export const BookInputForm: FC<Props> = memo((props) => {
  const { formData, onChangeText, onChangeDate } = props;

  return (
    <>
      <TextForm labelText={"バーコード番号(『978』から始まる10又は13桁の番号)"} name={"isbnCode"} onChangeText={onChangeText} value={formData.isbnCode} required={true}/>
      <TextForm labelText={"書籍名"} name={"bookName"} onChangeText={onChangeText} value={formData.bookName} required={true}/>
      <TextForm labelText={"出版社名"} name={"publisherName"} onChangeText={onChangeText} value={formData.publisherName} required={false}/>
      <TextForm labelText={"著者名"} name={"authorName"} onChangeText={onChangeText} value={formData.authorName} required={false}/>
      <CalendarTextForm labelText={"出版日"} name={"publishDate"} onChangeText={onChangeText} value={formData.publishDate} required={false} onChangeDate={onChangeDate}/>
    </>
  );
});
