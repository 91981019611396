import { ChangeEvent, FC, memo } from "react";
import { FormLabel } from "../atoms/FormLabel";
import { TextBox } from "../atoms/TextBox";
import { RequiredLabel } from "../atoms/RequiredLabel";

type Props = {
  labelText: string;
  name: string;
  onChangeText: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required: boolean;
};

export const TextForm: FC<Props> = memo((props) => {
  const { labelText, name, onChangeText, value, required } = props;

  return (
    <>
      <div className="mb-6">
        <FormLabel labelText={labelText} />
        <div className="col mb-0">
        {
          required && <RequiredLabel labelText={"必須"} />
        }
        </div>
        <TextBox name={name} onChange={onChangeText} value={value} />
      </div>
    </>
  );
});
