import { SecondaryButton } from "../atoms/SecondaryButton";
import { PrimaryButton } from "../atoms/PrimaryButton";
import { FC, memo } from "react";

type Props = {
  onClickCancel: () => void;
  onClickRegist: () => void;
};

export const RegistButtons: FC<Props> = memo((props) => {
  const { onClickCancel, onClickRegist } = props;
  return (
    <>
      <div className="flex justify-end items-center">
        <div className="mr-2 inline-block">
          <PrimaryButton onClick={onClickRegist}>登録</PrimaryButton>
        </div>
        <div className="ml-2 inline-block">
          <SecondaryButton onClick={onClickCancel}>キャンセル</SecondaryButton>
        </div>
      </div>
    </>
  );
});
