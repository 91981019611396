import axios from "axios";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { BookControlForm } from "../types/form/BookControlForm";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL || "";
const REGIST_BOOK_API_URL = "/api/bookRegist";

export const useRegistBook = () => {
  const [errorMessageList, setErrorMessageList] = useState<Array<string>>([]);
  const [responseStatus, setResponseStatus] = useState<number>(0);

  const registBook = useCallback(
    async (data: BookControlForm, userId: string, token: string) => {
      // レスポンスステータスをクリア
      setResponseStatus(0);
      axios
        .post(
          BASE_API_URL + REGIST_BOOK_API_URL,
          {
            isbnCode: data.isbnCode,
            bookName: data.bookName,
            authorName: data.authorName,
            publisherName: data.publisherName,
            publishDate: data.publishDate,
            userId: userId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response: any) => {
          if (response.status === 201) {
            let status = response.status;
            setResponseStatus(status);
            setErrorMessageList([]);
            toast.success("書籍を登録しました", {
              position: toast.POSITION.TOP_CENTER,
            });

          }
        })
        .catch((error) => {
          let status = error.status;
          setResponseStatus(status);
          setErrorMessageList(error.response.data.errorMessageList);
        });
    },
    [errorMessageList, responseStatus]
  );

  return { registBook, errorMessageList, responseStatus };
};
