import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSetRecoilState } from "recoil";
import { usersState } from "../store/usersState";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL || "";
const LOGIN_API_URL = "/api/user/login";
export const useLogin = () => {
  const navigate = useNavigate();
  const setUserData = useSetRecoilState(usersState);
  const [errorMessageList, setErrorMessageList] = useState<Array<string>>([]);

  const auth = useCallback(
    (userId: string, password: string) => {
      axios
        .post(BASE_API_URL + LOGIN_API_URL, {
          loginId: userId,
          password: password,
        })
        .then((response: any) => {
          if (response.status === 200) {
            setUserData({ token: response.headers["authorization"], userId: userId });
            navigate("/bookList");
          }
        })
        .catch((err) => {
          console.log(err);
          let newErrorMessageList = [];
          if (err.response.status === 403) {
            newErrorMessageList.push("ユーザーIDまたはパスワードが違います");
          } else {
            newErrorMessageList.push("システムエラーが発生しました。何度も続く場合は管理者にご連絡ください");
          }
          setErrorMessageList(newErrorMessageList);
        });
    },
    [navigate, setUserData]
  );
  return { auth, errorMessageList };
};
