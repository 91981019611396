import { FC } from "react";

type Props = {
  labelText: string;
};
export const FormLabel: FC<Props> = (props) => {
  const { labelText } = props;

  return (
    <>
      <label className="block mb-2 text-sm font-medium text-gray-900 float-left pr-2">{labelText}</label>
    </>
  );
};
