import { FC, memo } from "react";

/** 画面ローディングのコンポーネント */
export const Loading: FC = memo(() => {
  return (
    <>
      <div className="flex justify-center my-20 space-x-2 items-center">
        <span className="animate-bounce">Loading </span>
        <div className="animate-bounce h-4 w-4 bg-blue-600 rounded-full"></div>
        <div className="animate-bounce h-4 w-4 bg-blue-600 rounded-full animation-delay-200"></div>
        <div className="animate-bounce h-4 w-4 bg-blue-600 rounded-full animation-delay-400"></div>
      </div>
    </>
  );
});
