import { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export const SecondaryButton: FC<Props> = memo((props) => {
  const { children, onClick } = props;
  return (
    <button type="button" className="bg-geexpink-200 hover:bg-geexpink-100 text-white rounded px-4 py-2" onClick={onClick}>
      {children}
    </button>
  );
});
