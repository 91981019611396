import axios from "axios";
import { useCallback, useState } from "react";
import { BookInfo } from "../types/api/BookInfo";
import { BookSearchPanelForm } from "../types/form/BookSearchPanelForm";

export const useFetchBookList = () => {
  const BASE_API_URL: string | undefined = process.env.REACT_APP_API_BASE_URL;
  const [bookList, setBookList] = useState<Array<BookInfo>>([]);
  const [fetchCompleteFlag, setFetchCompleteFlag] = useState<boolean>(false);
  const [errorMessageList, setErrorMessageList] = useState<Array<string>>([]);
  const [responseStatus, setResponseStatus] = useState<number>(0);

  const fetchBookList = useCallback((token: string, params?: BookSearchPanelForm) => {
    const FETCH_BOOK_API_URL: string = params ? "/api/bookList/search" : "/api/bookList";
    axios
      .get(BASE_API_URL + FETCH_BOOK_API_URL, {
        headers: {
          Authorization: token,
        },
        params: params,
        data: {},
      })
      .then((response) => {
        setResponseStatus(response.status);
        setErrorMessageList([]);

        let fetchBookList: Array<BookInfo> = [];
        response.data.map((data: BookInfo) => {
          fetchBookList.push({
            id: data.id,
            isbnCode: data.isbnCode,
            bookName: data.bookName,
            authorName: data.authorName,
            publisherName: data.publisherName,
            publishDate: data.publishDate,
            duplicateBookNum: data.duplicateBookNum,
            lendId: data.lendId,
            bookId: data.bookId,
            lendDate: data.lendDate,
            returnDate: data.returnDate,
            lendEmployeeNo: data.lendEmployeeNo,
            employeeFullName: data.employeeFullName,
            remarks: data.remarks,
          });
          return true;
        });

        setBookList(() => ([...fetchBookList]));
        setFetchCompleteFlag(true);
      })
      .catch((error) => {
        setResponseStatus(error.status);
        setErrorMessageList(error.response.data.errorMessageList);
      });
  }, []);

  return { fetchBookList, bookList, fetchCompleteFlag, errorMessageList };
};
