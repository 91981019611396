import { ChangeEvent, FC, memo } from "react";

type Props = {
  name: string;
  value: string;
  isDisabled?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | undefined;
};

/** テキストボックスのコンポーネント */
export const TextBox: FC<Props> = memo((props) => {
  const { name, value, isDisabled = false, onChange } = props;
  return (
    <>
      <input
        name={name}
        type="text"
        className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"
        onChange={onChange}
        value={value}
        disabled={isDisabled}
      />
    </>
  );
});
