import { KeyboardEvent, FC, memo, ChangeEvent, Ref } from "react";
import { FormLabel } from "../atoms/FormLabel";
import { RequiredLabel } from "../atoms/RequiredLabel";
import { BarcodeTextBox } from "../atoms/BarcodeTextBox";

type Props = {
  labelText: string;
  name: string;
  onKeyDown: (e: KeyboardEvent<HTMLInputElement>) => void;
  onChangeText: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required: boolean;
  ref: Ref<HTMLInputElement>;
};

export const BarcodeTextForm: FC<Props> = memo((props) => {
  const { labelText, name, onKeyDown, onChangeText, value, required, ref } = props;

  return (
    <>
      <div className="mb-6">
        <FormLabel labelText={labelText} />
        <div className="col mb-0">
        {
          required && <RequiredLabel labelText={"必須"} />
        }
        </div>
        <BarcodeTextBox name={name} onKeyDown={onKeyDown} onChange={onChangeText} value={value} ref={ref} />
      </div>
    </>
  );
});
