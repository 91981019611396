import { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

/** 入力エラー時のメッセージ */
export const ErrorMessage: FC<Props> = memo((props) => {
  const { children } = props;
  return (
    <>
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded my-4" role="alert">
        <span className="block sm:inline">{children}</span>
      </div>
    </>
  );
});
