import { ChangeEvent, FC, memo, SetStateAction } from "react";
import { FormLabel } from "../atoms/FormLabel";
import { RequiredLabel } from "../atoms/RequiredLabel";
import { useSetRecoilState } from "recoil";
import { calendarModalInfoState } from "../../store/modal/calendarModalInfoState";
import { BookCalendarModal } from "../pages/modal/BookCalendarModal";
import { TextBox } from "../atoms/TextBox";
import { FcCalendar } from "react-icons/fc";

type Props = {
  labelText: string;
  name: string;
  onChangeText: (e: ChangeEvent<HTMLInputElement>) => void;
  value: string;
  required: boolean;
  onChangeDate?: (e: SetStateAction<Date>) => void;
};

export const CalendarTextForm: FC<Props> = memo((props) => {
  const { labelText, name, onChangeText, value, required, onChangeDate } = props;
  const setCalendarModalInfo = useSetRecoilState(calendarModalInfoState);

  // カレンダー押下
  const onClickCalendarButton = () => {
    setCalendarModalInfo({ isOpen: true });
    return;
  };

  return (
    <>
      <div className="mb-6">
        <FormLabel labelText={labelText} />
        <div className="col mb-0">
          {required && <RequiredLabel labelText={"必須"} />}
          <button
            className="p-0 text-xl border-none ml-2"
            type="button"
            data-modal-toggle="popup-modal"
            onClick={() => onClickCalendarButton()}
          >
            <FcCalendar />
          </button>
        </div>
        <TextBox name={name} onChange={onChangeText} value={value}/>
      </div>
      <BookCalendarModal calendarTarget={value} onChangeDate={onChangeDate}/>
    </>
  );
});
