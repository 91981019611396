import { FC, memo, useEffect } from "react";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { useRecoilState, useRecoilValue } from "recoil";
import { useDeleteBook } from "../../../hooks/useDeleteBook";
import { deleteModalInfoState } from "../../../store/modal/deleteModalInfoState";
import { usersState } from "../../../store/usersState";
import { BookControlForm } from "../../../types/form/BookControlForm";
import { ErrorMessage } from "../../atoms/ErrorMessage";
import { PrimaryButton } from "../../atoms/PrimaryButton";
import { SecondaryButton } from "../../atoms/SecondaryButton";

type Props = {
  deleteTarget: BookControlForm;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    width: "30%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
  },
};

export const BookDeleteConfirmModal: FC<Props> = memo((props) => {
  const [modalInfo, setModalInfo] = useRecoilState(deleteModalInfoState);
  const { deleteTarget } = props;
  const { deleteBook, errorMessageList, responseStatus } = useDeleteBook();
  const user = useRecoilValue(usersState);

  // 200ステータス(削除完了)が返却されたらフォームをクリア
  useEffect(() => {
    if (responseStatus === 200) {
      setModalInfo({ isOpen: false });
      const toastMessage = new Promise<void>((resolve) => {
        toast.success("書籍を削除しました", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 1000,
        });
        resolve();
      });
      // toast表示後2秒後にリフレッシュ
      toastMessage.then(() => {
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseStatus]);

  const closeModal = () => {
    setModalInfo({ isOpen: false });
  };

  // キャンセルボタン押下
  const cancel = () => {
    setModalInfo({ isOpen: false });
  };

  // 決定ボタン押下
  const deleteData = () => {
    deleteBook(deleteTarget, user.userId, user.token);
  };

  return (
    <>
      <Modal isOpen={modalInfo.isOpen} onRequestClose={closeModal} style={customStyles}>
        <div className="font-body">
          {
            // エラーメッセージ表示
            errorMessageList !== undefined && errorMessageList.length ? (
              <ErrorMessage>
                {errorMessageList.map((errorMessage) => {
                  return (
                    <>
                      {errorMessage}
                      <br />
                    </>
                  );
                })}
              </ErrorMessage>
            ) : (
              ""
            )
          }
          <div>以下書籍を削除します。よろしいですか？</div>
          <div className="pt-2 break-all">
            <div>書籍名：{deleteTarget.bookName}</div>
          </div>
          <div className="flex flex-row justify-end items-center pt-4">
            <div className="pr-2">
              <PrimaryButton onClick={deleteData}>OK</PrimaryButton>
            </div>
            <div className="pr-2">
              <SecondaryButton onClick={cancel}>キャンセル</SecondaryButton>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});
