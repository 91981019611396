import axios from "axios";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { BookLendReturnForm } from "../types/form/BookLendReturnForm";

export const useUpdateLendInfo = () => {
  const BASE_API_URL = process.env.REACT_APP_API_BASE_URL || "";
  const UPDATE_LENDINFO_API_URL = "/api/updateLendInfo";

  const [errorMessageList, setErrorMessageList] = useState<Array<string>>([]);
  const [responseStatus, setResponseStatus] = useState<number>(0);

  const updateLendInfo = useCallback(
    async (data: BookLendReturnForm, userId: string, token: string) => {
      axios
        .post(
          BASE_API_URL + UPDATE_LENDINFO_API_URL,
          {
            id: data.id, // 貸借履歴のid
            bookId: data.bookId, // 返却処理では不要な情報だがAPI側のチェックがあるため入れている
            lendDate: `${data.lendDate.slice(0, 4)}/${data.lendDate.slice(5, 7)}/${data.lendDate.slice(8, 10)}`, // 返却処理では不要な情報だがAPI側のチェックがあるため入れている
            lendEmployeeNo: data.lendEmployeeNo, // 返却処理では不要な情報だがAPI側のチェックがあるため入れている
            returnDate: data.returnDate,
            remarks: data.remarks,
            userId: userId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response: any) => {
          if (response.status === 200) {
            let status = response.status;
            setResponseStatus(status);
            setErrorMessageList([]);
            const toastMessage = new Promise<void>((resolve) => {
              toast.success("返却完了しました", {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 1000,
              });
              resolve();
            });

            toastMessage.then(() => {
              setTimeout(() => {
                window.location.reload();
              }, 2000);
            });
          }
        })
        .catch((error) => {
          let status = error.status;
          setResponseStatus(status);
          setErrorMessageList(error.response.data.errorMessageList);
        });
    },
    [errorMessageList, responseStatus]
  );
  return { updateLendInfo, errorMessageList };
};
