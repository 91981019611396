import React, { ChangeEvent } from "react";
import { useState, useEffect, useRef, SetStateAction } from "react";
import { DefaultTemplete } from "../templetes/DefaultTemplete";
import { FormLabel } from "../atoms/FormLabel";
import { useNavigate } from "react-router-dom";
import "../../App.css";
import axios from "axios";
import { BookControlForm } from "../../types/form/BookControlForm";
import { BookSearchInfoForm } from "../organisms/BookSearchInfoForm";
import { SecondaryButton } from "../atoms/SecondaryButton";
import { PrimaryButton } from "../atoms/PrimaryButton";
import { BarcodeTextForm } from "../molecules/BarcodeTextForm";
import { RadioBox } from "../atoms/RadioBox";
import { toast } from "react-toastify";
import { TertiaryButton } from "../atoms/TertiaryButton";

export const BookBarcode = (props: any) => {
  const firstUpdate = useRef(true);
  const [isStart, setIsStart] = useState<boolean>(false);
  const [formClassName, setformClassName] = useState<string>("mb-4");
  const Quagga = require("quagga");
  const navigate = useNavigate();
  const [formData, setFormData] = useState<BookControlForm>({
    isbnCode: "",
    bookName: "",
    authorName: "",
    publisherName: "",
    publishDate: "",
  });

  const [val, setVal] = useState("web");
  const handleChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setVal(e.target.value);
    setIsStart(false);
    setFormData({
      ...formData,
      isbnCode: "",
      bookName: "",
      authorName: "",
      publisherName: "",
      publishDate: "",
    });
  }
  const [text, setText] = useState("");
  const inputElement = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return () => {
      if (val === "web") {
        if (isStart) stopScanner();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStart]);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (val === "web") {
      if (isStart) startScanner();
      else stopScanner();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStart]);

  let code: React.SetStateAction<string>;
  let count = 0;

  // バーコード検出
  const _onDetected = (res: { codeResult: { code: SetStateAction<string> } }) => {
    if (res.codeResult.code.toString().slice(0, 3) === "978") {
      if (code === res.codeResult.code) {
        count++;
      } else {
        count = 0;
        code = res.codeResult.code;
      }
      if (count >= 2) {
        // 書籍情報を取得
        getBookInfo(res.codeResult.code.toString());
      }
    }
  };

  // バーコード開始
  const startScanner = () => {
    const config = {
      inputStream: {
        type: "LiveStream",
        target: document.querySelector("#video_container"),
        constraints: {
          width: 360,
          height: 115,
          audio: false,
          facingMode: "environment",
        },
        area: {
          // defines rectangle of the detection/localization area
          top: "10%", // top offset
          right: "5%", // right offset
          left: "5%", // left offset
          bottom: "5%", // bottom offset
        },
        singleChannel: false, // true: only the red color-channel is read
      },
      numOfWorkers: navigator.hardwareConcurrency,
      locate: false,
      frequency: 1,
      multiple: false,
      locator: {
        halfSample: true,
        patchSize: "large", // x-small, small, medium, large, x-large
      },
      decoder: {
        readers: [
          {
            format: "ean_reader",
            multiple: false,
            config: {},
          },
        ],
      },
    };
    Quagga.init(config, (err: any) => {
      if (err) {
        return console.log(err);
      }
      Quagga.start();
    });

    Quagga.onDetected(_onDetected);
  };

  // バーコード認識
  Quagga.onProcessed((result: { boxes: any[]; box: any; codeResult: { code: any }; line: any }) => {
    let drawingCtx = Quagga.canvas.ctx.overlay,
      drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      if (result.boxes) {
        drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
        result.boxes
          .filter((box) => box !== result.box)
          .forEach((box) => {
            Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
              color: "green",
              lineWidth: 4,
            });
          });
      }

      if (result.codeResult && result.codeResult.code) {
        Quagga.ImageDebug.drawPath(result.line, { x: "x", y: "y" }, drawingCtx, { color: "red", lineWidth: 3 });
      }
    }
  });

  // バーコード停止
  const stopScanner = () => {
    Quagga.offProcessed();
    Quagga.offDetected();
    Quagga.stop();
  };

  // 書籍情報取得
  const getBookInfo = async (barcode: string) => {
    let bookName: string;
    let authorName: string;
    let publisherName: string;
    let publishDate: string;

    // GoogleBooksから書籍情報を取得
    const result = await searchGoogleBooks(barcode);
    if (result.isSuccess) {
      if(result.data.totalItems === 1) {
        // eslint-disable-next-line array-callback-return
        result.data.items.map((item: any) => {
          // 書籍名
          bookName = item.volumeInfo.title;

          // 出版社名
          if (item.volumeInfo.publisher != null) {
            publisherName = item.volumeInfo.publisher;
          } else {
            publisherName = "";
          }

          // 著者名
          let authors = "";
          if (item.volumeInfo.authors != null) {
            // eslint-disable-next-line array-callback-return
            item.volumeInfo.authors.map((author: any) => {
              if (authors === "") {
                authors = author;
              } else {
                authors +=", " + author;
              }
            });
            authorName = authors;
          } else {
            authorName = "";
          }

          // 出版日
          if (item.volumeInfo.publishedDate != null) {
            publishDate = item.volumeInfo.publishedDate;
            if (publishDate.length === 10) {
              // 読み取った出版日が 9999-99-99 形式なので、9999/99/99 形式に変換
              publishDate = publishDate.replace(/-/g, "/");
            } else {
              // 年月日が設定されていない（日がない等）場合は出版日は未設定とする
              publishDate = "";
            }
          } else {
            publishDate = "";
          }

          setFormData({
            ...formData,
            isbnCode: barcode,
            bookName: bookName,
            authorName: authorName,
            publisherName: publisherName,
            publishDate: publishDate,
          });

          toast.success("書籍情報を取得しました", {
            position: toast.POSITION.TOP_CENTER,
            autoClose: 2000,
          });
        });
      } else {
        toast.error("書籍情報を取得できませんでした", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 2000,
        });

        setFormData({
          ...formData,
          isbnCode: barcode,
          bookName: "",
          authorName: "",
          publisherName: "",
          publishDate: "",
        });

      }
    } else {

      window.alert(String(result.error));
    }
  };

  // GoogleBooksから書籍情報取得
  const searchGoogleBooks = async (searchString: string) => {
    try {
      const response = await axios.get(`https://www.googleapis.com/books/v1/volumes?q=isbn:${searchString}`);
      return { isSuccess: true, data: response.data, error: null };
    } catch (error) {
      return { isSuccess: false, data: null, error };
    }
  };

  // キャンセルボタン押下
  const cancelSearch = () => {
    if (val === "web") {
      if (isStart) stopScanner();
    }
    navigate("/bookRegist", { state: { rst: false } });
  };

  // 決定ボタン押下
  const okSearch = () => {
    if (val === "web") {
      Quagga.offProcessed();
      Quagga.offDetected();
      Quagga.stop();
    }
    navigate("/bookRegist", { state: { rst: true, resFormData: formData } });
  };

  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setText(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  // 書籍検索
  const okBookSearch = () => {
    if(text === "") {
      toast.error("バーコードは必須です", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 2000,
      });
      return;     
    }
    getBookInfo(text);
  };

  // クリア
  const clearBarcode = () => {
    setText("");
    inputElement.current?.focus();
  };

  const handleSubmit = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    getBookInfo(text);
  }

  return (
    <>
      <DefaultTemplete title={"バーコード読取"}>
        {!isStart && (
          <>
          <div className="flex flex-row mb-2">
            <div className="pr-4">
              <label>
                <RadioBox name={"web"} state={val} onChange={handleChange} item={"web"} disabled={false} />
                webカメラ
              </label>
            </div>
            <label>
              <RadioBox name={"usb"} state={val} onChange={handleChange} item={"usb"} disabled={false} />
              USBバーコードリーダー
            </label>
          </div>
          </>
        )}
        {val === "web" && isStart && (
          <>
          <div className="flex flex-row mb-2">
            <div className="pr-4">
              <label>
                <RadioBox name={"web"} state={val} onChange={handleChange} item={"web"} disabled={false} />
                webカメラ
              </label>
            </div>
            <label>
              <RadioBox name={"usb"} state={val} onChange={handleChange} item={"usb"} disabled={true} />
              USBバーコードリーダー
            </label>
          </div>
          </>
        )}
        {val === "usb" && isStart && (
          <>
          <div className="flex flex-row mb-2">
            <div className="pr-4">
              <label>
                <RadioBox name={"web"} state={val} onChange={handleChange} item={"web"} disabled={true} />
                webカメラ
              </label>
            </div>
            <label>
              <RadioBox name={"usb"} state={val} onChange={handleChange} item={"usb"} disabled={false} />
              USBバーコードリーダー
            </label>
          </div>
          </>
        )}
        <div className="lg:max-w-3xl md:max-w-md">
          <div className="mb-2">
            <PrimaryButton
              onClick={() => {
                setIsStart((prevStart) => !prevStart);
                if (!isStart) {
                  if(val === "web" ) {
                    setformClassName("mt-32 mb-4");
                  } else {
                    setformClassName("mt-8 mb-4");
                  }
                } else {
                  setformClassName("mb-4");
                }
              }}
            >
              {isStart ? "停止" : "開始"}
            </PrimaryButton>
          </div>
        </div>
        {val === "web" && isStart && (
          <>
            <div>
              <FormLabel
                labelText={"バーコードを緑の四角部分に合わせてください。"}
              />
              <br />
              <div id="video_container" />
            </div>
          </>
        )}
        {val === "usb" && isStart && (
          <>
            <BarcodeTextForm
              labelText={"バーコード(『978』から始まる10又は13桁の番号)を読み取ってください。"}
              name={"isbnCode"}
              onKeyDown={handleKeyDown}
              onChangeText={onChangeText}
              value={text}
              required={true}
              ref={inputElement}
            />
            <div className="flex flex-row mb-8">
              <div className="pr-2">
                <TertiaryButton onClick={clearBarcode}>クリア</TertiaryButton>
              </div>
              <div className="pr-2">
              <PrimaryButton onClick={okBookSearch}>書籍検索</PrimaryButton>
              </div>
            </div>
          </>
        )}
        <div className={formClassName}>
          <BookSearchInfoForm formData={formData} />
        </div>
        <div className="flex flex-row justify-end items-center">
          <div className="pr-2">
            {isStart && (
              <>
                <PrimaryButton onClick={okSearch}>決定</PrimaryButton>
              </>
            )}
          </div>
          <div className="pr-2">
            <SecondaryButton onClick={cancelSearch}>キャンセル</SecondaryButton>
          </div>
        </div>
      </DefaultTemplete>
    </>
  );
};
