import { FC, memo, ReactNode, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { useAuth } from "../../hooks/useAuth";
import { permissionState } from "../../store/permissionState";
import { usersState } from "../../store/usersState";
import { Loading } from "../atoms/Loading";
import { PageTitle } from "../atoms/PageTitle";
import { Footer } from "../organisms/Footer";

type Props = {
  title: string;
  children: ReactNode;
};

export const DefaultTemplete: FC<Props> = memo((props) => {
  const { title, children } = props;
  const user = useRecoilValue(usersState);
  const permission = useRecoilValue(permissionState);
  const { authCheck } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const auth = new Promise<void>((resolve) => {
      authCheck(user.token);
      resolve();
    });

    auth.then(() => {
      if (permission.permission) {
        setIsLoading(false);
      } else {
        navigate("/");
      }
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className="p-4 mx-auto lg:max-w-6xl md:max-w-3xl my-4 font-body ">
            <PageTitle>{title}</PageTitle>
            {children}
          </div>
          <Footer />
        </>
      )}
    </>
  );
});
