import axios from "axios";
import { useCallback, useState } from "react";
import { DuplicateBookInfo } from "../types/api/DuplicateBookInfo";
import { BookControlForm } from "../types/form/BookControlForm";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL || "";
const FETCH_REGIST_BOOK_API_URL = "/api/bookRegist/search";

export const useFetchRegistBook = () => {
  const [errorMessageList, setErrorMessageList] = useState<Array<string>>([]);
  const [responseStatus, setResponseStatus] = useState<number>(0);
  const [duplicateBookInfo, setDuplicateBookInfo] = useState<DuplicateBookInfo>({ duplicateBookNum: 0 });

  const fetchRegistBook = useCallback(
    async (data: BookControlForm, userId: string, token: string) => {
      return axios
        .post(
          BASE_API_URL + FETCH_REGIST_BOOK_API_URL,
          {
            isbnCode: data.isbnCode,
            bookName: data.bookName,
            authorName: data.authorName,
            publisherName: data.publisherName,
            publishDate: data.publishDate,
            userId: userId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response) => {
          if (response.status === 200) {
            let status = response.status;
            setResponseStatus(status);
            setErrorMessageList([]);

            let duplicateBook: DuplicateBookInfo = {
              duplicateBookNum: response.data.duplicateBookNum,
            };
            setDuplicateBookInfo(duplicateBook);
            return Promise.resolve(duplicateBook);
          }
        })
        .catch((error) => {
          let status = error.status;
          setResponseStatus(status);
          setErrorMessageList(error.response.data.errorMessageList);
          return Promise.reject(error);
        });
    },
    [errorMessageList, responseStatus]
  );

  return { fetchRegistBook, duplicateBookInfo, errorMessageList };
};
