import { FC, memo, SetStateAction, useEffect, useState } from "react";
import Modal from "react-modal";
import { useRecoilState } from "recoil";
import { calendarModalInfoState } from "../../../store/modal/calendarModalInfoState";
import { Calendar } from "react-calendar";
import "react-calendar/dist/Calendar.css";

type Props = {
  id?: string | undefined;
  calendarTarget: string;
  onChangeDate?: (e: SetStateAction<Date>) => void | undefined;
};

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.4)",
  },
};

const checkStrDate = (strDate: string) => {
  if(!strDate.match(/^\d{4}\/\d{2}\/\d{2}$/)){
    return false;
  }
  const yyyy = Number(strDate.split("/")[0]);
  const mm = Number(strDate.split("/")[1]) - 1;
  const dd = Number(strDate.split("/")[2]);
  const date = new Date(yyyy, mm, dd);
  if(date.getFullYear() !== yyyy || date.getMonth() !== mm || date.getDate() !== dd){
    return false;
  }

  return true;
};

export const BookCalendarModal: FC<Props> = memo((props) => {
  const [modalInfo, setModalInfo] = useRecoilState(calendarModalInfoState);
  const { id, calendarTarget, onChangeDate } = props;
  const [dateState, setDateState] = useState(new Date());
  const changeDate = (e: SetStateAction<Date>) => {
    onChangeDate && onChangeDate(e);
    setModalInfo({ isOpen: false });
  };

  useEffect(() => {
    if(calendarTarget === undefined ) {
      return;
    }
    if(calendarTarget === ""){
      setDateState(new Date());
    }
    if(checkStrDate(calendarTarget)) {
      setDateState(new Date(`${calendarTarget.slice(0, 4)}/${calendarTarget.slice(5, 7)}/${calendarTarget.slice(8,10)}`));
    }
    else{
      return;
    }
  }, [calendarTarget]);

  const closeModal = () => {
    setModalInfo({ isOpen: false });
  };


  return (
    <>
      <Modal
        id={id}
        isOpen={modalInfo.isOpenId?(id === modalInfo.isOpenId):modalInfo.isOpen}
        onRequestClose={closeModal}
        style={customStyles}
      >
        <div className="pb-2">日付を選択してください</div>
        <Calendar locale="ja-JP" calendarType="US" value={dateState} 
        onChange={changeDate} 
        />
      </Modal>
    </>
  );
});
