import { ChangeEvent, memo, useState } from "react";
import { useLogin } from "../../hooks/useLogin";
import { ErrorMessage } from "../atoms/ErrorMessage";
import { FormLabel } from "../atoms/FormLabel";
import { PasswordBox } from "../atoms/PasswordBox";
import { PrimaryButton } from "../atoms/PrimaryButton";
import { TextBox } from "../atoms/TextBox";

import LogoImage from "../../images/geexlibrary_logo.png";

type FormData = {
  loginId: string;
  password: string;
};

/** ログイン画面 */
export const LoginPage = memo(() => {
  const [formData, setFormData] = useState<FormData>({
    loginId: "",
    password: "",
  });

  const [errorMessage, setErrorMessage] = useState<string>("");
  const { auth, errorMessageList } = useLogin();

  // フォームデータのstate更新
  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // ログインボタン押下
  const login = () => {
    // 一旦きれいにする
    setErrorMessage("");
    // 入力チェック
    if (formData.loginId === "" || formData.password === "") {
      setErrorMessage("ユーザーIDとパスワードは必須です");
      return;
    }
    auth(formData.loginId, formData.password);
  };

  return (
    <>
      <div className="bg-white py-6 sm:py-8 lg:py-12 font-body">
        <div className="max-w-screen-2xl px-4 md:px-8 mx-auto">
          <div className="text-center mx-auto mb-2 flex justify-center">
            <img width="200" height="200" src={LogoImage} alt="logo" />
          </div>
          <div className="max-w-lg mx-auto">
            {errorMessageList.length !== 0 ? (
              <ErrorMessage>
                {errorMessageList.map((errorMessage, index) => {
                  return (
                    <>
                      <div key={index}>{errorMessage}</div>
                    </>
                  );
                })}
              </ErrorMessage>
            ) : (
              ""
            )}
            {errorMessage ? <ErrorMessage>{errorMessage}</ErrorMessage> : ""}
            <div className="flex flex-col gap-4 p-4 md:p-8 border-gray-200 border rounded-lg mx-auto">
              <div>
                <FormLabel labelText={"ユーザーID"} />
                <TextBox name={"loginId"} onChange={onChangeText} value={formData.loginId} />
              </div>
              <div>
                <FormLabel labelText={"パスワード"} />
                <PasswordBox name={"password"} onChange={onChangeText} value={formData.password} />
              </div>
              <PrimaryButton onClick={login}>ログイン</PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
