import { KeyboardEvent, FC, memo, ChangeEvent, Ref } from "react";

type Props = {
  name: string;
  value: string;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void | undefined;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void | undefined;
  ref: Ref<HTMLInputElement>;
};

/** テキストボックスのコンポーネント */
export const BarcodeTextBox: FC<Props> = memo((props) => {
  const { name, value, onKeyDown, onChange, ref } = props;
  return (
    <>
      <input
        name={name}
        type="text"
        className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2"
        onKeyDown={onKeyDown}
        onChange={onChange}
        value={value}
        autoFocus={true}
        autoComplete="true"
        ref={ref}
      />
    </>
  );
});
