import axios from "axios";
import { useCallback, useState } from "react";
import { EmployeeInfo } from "../types/api/EmployeeInfo";

export const useFetchEmployeeList = () => {
  const BASE_API_URL: string | undefined = process.env.REACT_APP_API_BASE_URL;
  const FETCH_EMPLOYEE_API_URL: string = "/api/employeeList";
  const [employeeList, setEmployeeList] = useState<Array<EmployeeInfo>>([]);

  const fetchEmployeeList = useCallback((token: string) => {
    axios
      .get(BASE_API_URL + FETCH_EMPLOYEE_API_URL, {
        headers: {
          Authorization: token,
        },
        data: {},
      })
      .then((response) => {
        let fetchEmployeeList: Array<EmployeeInfo> = [];
        response.data.map((data: EmployeeInfo) => {
          fetchEmployeeList.push({
            employeeNo: data.employeeNo,
            employeeName: data.employeeName,
          });
          return true;
        });

        setEmployeeList(fetchEmployeeList);
      })
      .catch((err) => {
        return;
      });
  }, []);

  return { fetchEmployeeList, employeeList };
};
