import LogoImage from "../../images/geexlibrary_logo.png";

export const Footer = () => {
  return (
    <footer className="p-3 bg-white md:px-5 md:py-3">
      <hr className="my-1 border-gray-200 sm:mx-auto lg:my-1" />
      <img className="m-auto" width="60" height="60" src={LogoImage} alt="logo" />
      <div className="text-center">
        <span className="block text-sm text-gray-500">© 2022 GEEX Co., Ltd.</span>
      </div>
    </footer>
  );
};
