import { PageTitle } from "../../atoms/PageTitle";
import { PrimaryButton } from "../../atoms/PrimaryButton";
import { SecondaryButton } from "../../atoms/SecondaryButton";
import Modal from "react-modal";
import { useRecoilState, useRecoilValue } from "recoil";
import { lendAndReturnModalInfoState } from "../../../store/modal/lendAndReturnModalInfoState";
import { ChangeEvent, FC, memo, SetStateAction, useEffect, useState } from "react";
import { LendReturnInputForm } from "../../organisms/LendReturnInputForm";
import { BookLendReturnForm } from "../../../types/form/BookLendReturnForm";
import { useRegistLendInfo } from "../../../hooks/useRegistLendInfo";
import { usersState } from "../../../store/usersState";
import { ErrorMessage } from "../../atoms/ErrorMessage";
import { useUpdateLendInfo } from "../../../hooks/useUpdateLendInfo";
import { EmployeeInfo } from "../../../types/api/EmployeeInfo";

type Props = {
  lendReturnTarget: BookLendReturnForm;
  lendFlag: boolean;
  employeeList: Array<EmployeeInfo>;
};

export const BookLendAndReturnModal: FC<Props> = memo((props) => {
  const { lendReturnTarget, lendFlag, employeeList } = props;
  const user = useRecoilValue(usersState);
  const { registLendInfo, errorMessageList: lendErrorMessageList } = useRegistLendInfo();
  const { updateLendInfo, errorMessageList: returnErrorMessageList } = useUpdateLendInfo();
  const [modalInfo, setModalInfo] = useRecoilState(lendAndReturnModalInfoState);
  const initialData = {
    id: 0,
    bookName: "",
    bookId: 0,
    lendEmployeeNo: "",
    lendDate: "",
    returnDate: "",
    remarks: "",
    employeeFullName: "",
  };
  const [lendReturnForm, setLendReturnForm] = useState<BookLendReturnForm>(initialData);
  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      width: "30%",
      transform: "translate(-50%, -50%)",
    },
    overlay: {
      background: "rgba(0, 0, 0, 0.4)",
    },
  };

  const getDisplayDate = (date = new Date()) => {
    return `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, "0")}/${String(date.getDate()).padStart(2, "0")}`;
  };

  const onChangeForm = (e: ChangeEvent<any>) => {
    const { name, value } = e.target;
    setLendReturnForm({ ...lendReturnForm, [name]: value });
  };

  useEffect(() => {
    if (!lendFlag) {
      // 貸出処理の場合
      lendReturnTarget.lendDate = getDisplayDate();
    } else {
      // 返却処理の場合
      lendReturnTarget.lendDate = getDisplayDate(new Date(lendReturnTarget.lendDate));
      lendReturnTarget.returnDate = getDisplayDate();
    }
    setLendReturnForm(lendReturnTarget);
  }, [lendReturnTarget]);

  // キャンセルボタン押下
  const cancel = () => {
    setLendReturnForm(initialData);
    closeModal();
  };

  const closeModal = () => {
    setModalInfo({ isOpen: false });
  };

  // 貸出ボタン押下
  const lendBook = () => {
    registLendInfo(lendReturnForm, user.userId, user.token);
  };

  // 返却ボタン押下
  const returnBook = () => {
    updateLendInfo(lendReturnForm, user.userId, user.token);
  };

  const onChangeDate = (e: SetStateAction<Date>) => {
    const date = new Date(e.toString());
    if (!lendFlag) {
      // 貸出日
      setLendReturnForm({ ...lendReturnForm, "lendDate": date.getFullYear().toString() + "/" + ('00' + (date.getMonth() + 1)).slice(-2) + "/" + ('00' + date.getDate()).slice(-2) });
    } else {
      // 返却日
      setLendReturnForm({ ...lendReturnForm, "returnDate": date.getFullYear().toString() + "/" + ('00' + (date.getMonth() + 1)).slice(-2) + "/" + ('00' + date.getDate()).slice(-2) });
    }
  };

  return (
    <Modal isOpen={modalInfo.isOpen} onRequestClose={closeModal} style={customStyles}>
      <div className="font-body">
        <PageTitle>{!lendFlag ? "書籍貸出" : "書籍返却"}</PageTitle>
        {!lendFlag ? (
          // 入力エラーメッセージ表示
          lendErrorMessageList !== undefined && lendErrorMessageList.length ? (
            <ErrorMessage>
              {lendErrorMessageList.map((errorMessage) => {
                return (
                  <>
                    {errorMessage}
                    <br />
                  </>
                );
              })}
            </ErrorMessage>
          ) : (
            ""
          )
        ) : // 返却のエラーメッセージ
        returnErrorMessageList !== undefined && returnErrorMessageList.length ? (
          <ErrorMessage>
            {returnErrorMessageList.map((errorMessage) => {
              return (
                <>
                  {errorMessage}
                  <br />
                </>
              );
            })}
          </ErrorMessage>
        ) : (
          ""
        )}
        <LendReturnInputForm lendFlag={lendFlag} formData={lendReturnForm} onChangeForm={onChangeForm} employeeList={employeeList} onChangeDate={onChangeDate} />
        <div className="flex flex-row justify-end items-center pt-4">
          <div className="pr-2">
            {!lendFlag ? <PrimaryButton onClick={lendBook}>貸出</PrimaryButton> : <PrimaryButton onClick={returnBook}>返却</PrimaryButton>}
          </div>
          <div className="pr-2">
            <SecondaryButton onClick={cancel}>キャンセル</SecondaryButton>
          </div>
        </div>
      </div>
    </Modal>
  );
});
