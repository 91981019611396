import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

type permission = {
  permission: boolean;
};

const { persistAtom } = recoilPersist();

export const permissionState = atom<permission>({
  key: "permissionState",
  default: {
    permission: false,
  },
  effects_UNSTABLE: [persistAtom],
});
