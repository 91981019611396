import { FC, memo } from "react";
import { FcCalendar } from "react-icons/fc";

/** FcCalendarのコンポーネント */
export const FcCalendarMemo: FC = memo((props) => {
  return (
    <>
      <FcCalendar />
    </>
  );
});
