import { ChangeEvent, memo, SetStateAction, useEffect, useRef, useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useRegistBook } from "../../hooks/useRegistBook";
import { usersState } from "../../store/usersState";
import { BookControlForm } from "../../types/form/BookControlForm";
import { ErrorMessage } from "../atoms/ErrorMessage";
import { BookInputForm } from "../organisms/BookInputForm";
import { DefaultTemplete } from "../templetes/DefaultTemplete";
import { BarcodeButton } from "../atoms/BarcodeButton";
import { useLocation, useNavigate } from "react-router-dom";
import { RegistButtons } from "../molecules/RegistButtons";
import { useFetchRegistBook } from "../../hooks/useFetchRegistBook";
import { BookRegistConfirmModal } from "./modal/BookRegistConfirmModal";
import { registModalInfoState } from "../../store/modal/registModalInfoState";

/** 書籍登録画面 */
export const BookRegist = memo(() => {
  const user = useRecoilValue(usersState);
  const { registBook, errorMessageList, responseStatus } = useRegistBook();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<BookControlForm>({
    isbnCode: "",
    bookName: "",
    authorName: "",
    publisherName: "",
    publishDate: "",
  });
  const location = useLocation();
  const [responseSearch, setResponseSearch] = useState<{ rst: boolean }>(location.state as { rst: boolean });
  const searchResult = location.state as { resFormData: BookControlForm };

  useEffect(() => {
    if (responseSearch.rst) {
      setFormData({
        isbnCode: searchResult.resFormData.isbnCode,
        bookName: searchResult.resFormData.bookName,
        authorName: searchResult.resFormData.authorName,
        publisherName: searchResult.resFormData.publisherName,
        publishDate: searchResult.resFormData.publishDate,
      });
      setResponseSearch({ rst: false });
    }
  }, [responseSearch.rst]);

  // 201ステータス(登録完了)が返却されたらフォームをクリア
  useEffect(() => {
    if (responseStatus === 201) {
      setFormData({
        isbnCode: "",
        bookName: "",
        authorName: "",
        publisherName: "",
        publishDate: "",
      });
    }
  }, [responseStatus]);

  // フォームデータのstate更新
  const onChangeText = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const onChangeDate = (e: SetStateAction<Date>) => {
    const date = new Date(e.toString());
    setFormData({ ...formData, publishDate: date.getFullYear().toString() + "/" + ("00" + (date.getMonth() + 1)).slice(-2) + "/" + ("00" + date.getDate()).slice(-2) });
  };

  // 登録ボタン押下
  const { fetchRegistBook, duplicateBookInfo, errorMessageList: duplicateErrorMessageList } = useFetchRegistBook();
  const setRegistModalInfo = useSetRecoilState(registModalInfoState);
  const registData = () => {
    fetchRegistBook(formData, user.userId, user.token).then((duplicateBook) => {
      if (duplicateBook && duplicateBook.duplicateBookNum > 0) {
        setRegistModalInfo((prevModalInfo) => ({ ...prevModalInfo, isOpen: true }));
      } else {
        registBook(formData, user.userId, user.token);
      }
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // OKボタン押下 (in 重複本警告ポップアップ)
  const registDataFromModal = async () => {
    registBook(formData, user.userId, user.token);
    await setRegistModalInfo((prevModalInfo) => ({ ...prevModalInfo, isOpen: false }));
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // 表示用エラーメッセージリスト(hooks側で生成したもので書き換える。)
  const [dispErrorMessageList, setDispErrorMessageList] = useState<Array<string>>([]);
  const refErrorMessageList = useRef(errorMessageList);
  const refDuplicateErrorMessageList = useRef(duplicateErrorMessageList);
  useEffect(() => {
    // 前回のstate
    const prevErrorMessageList = refErrorMessageList.current;
    const prevDuplicateErrorMessageList = refDuplicateErrorMessageList.current;

    if (!arrayStringEqual(prevErrorMessageList, errorMessageList)) {
      setDispErrorMessageList(errorMessageList);
    }
    if (!arrayStringEqual(prevDuplicateErrorMessageList, duplicateErrorMessageList)) {
      setDispErrorMessageList(duplicateErrorMessageList);
    }

    // 今回のstateを保持
    refErrorMessageList.current = errorMessageList;
    refDuplicateErrorMessageList.current = duplicateErrorMessageList;
  }, [errorMessageList, duplicateErrorMessageList]);

  // Array<string>の等価判定
  const arrayStringEqual = (obj1: Array<string>, obj2: Array<string>): boolean => {
    if (!Array.isArray(obj1)) return false;
    if (!Array.isArray(obj2)) return false;
    if (obj1.length !== obj2.length) return false;
    for (let i = 0; i < obj1.length; ++i) {
      if (obj1[i] !== obj2[i]) return false;
    }
    return true;
  };

  // バーコードボタン押下
  const barcode = () => {
    navigate("/bookBarcode");
  };

  const cancel = () => {
    navigate("/bookList");
  };
  return (
    <>
      <DefaultTemplete title={"書籍登録"}>
        <div className="mx-auto lg:max-w-3xl md:max-w-xs">
          {
            // 入力エラーメッセージ表示
            dispErrorMessageList.length ? (
              <ErrorMessage>
                {dispErrorMessageList.map((errorMessage) => {
                  return (
                    <>
                      {errorMessage}
                      <br />
                    </>
                  );
                })}
              </ErrorMessage>
            ) : (
              ""
            )
          }
          <BarcodeButton onClick={barcode}>バーコード読取</BarcodeButton>
          <BookInputForm formData={formData} onChangeText={onChangeText} onChangeDate={onChangeDate} />
          <RegistButtons onClickCancel={cancel} onClickRegist={registData} />
          <BookRegistConfirmModal returnTarget={formData} duplicateBook={duplicateBookInfo} onClickRegist={registDataFromModal} />
        </div>
      </DefaultTemplete>
    </>
  );
});
