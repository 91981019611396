import { ChangeEvent, FC, memo } from "react";

type Props = {
  name: string;
  state: React.SetStateAction<string>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  item: string;
  disabled: boolean;
};

/** ラジオボックスのコンポーネント */
export const RadioBox: FC<Props> = memo((props) => {
  const { name, state, onChange, item, disabled } = props;
  return (
    <>
        <input type="radio" name={name} value={item} checked={item === state} onChange={onChange} disabled={disabled}/>
    </>
  );
});
