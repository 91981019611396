import axios from "axios";
import { useCallback, useState } from "react";
import { BookControlForm } from "../types/form/BookControlForm";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL || "";
const DELETE_BOOK_API_URL = "/api/bookDelete";

export const useDeleteBook = () => {
  const [errorMessageList, setErrorMessageList] = useState<Array<string>>([]);
  const [responseStatus, setResponseStatus] = useState<number>(0);

  const deleteBook = useCallback(
    async (data: BookControlForm, userId: string, token: string) => {
      axios
        .post(
          BASE_API_URL + DELETE_BOOK_API_URL,
          {
            id: data.id,
            userId: userId,
          },
          {
            headers: {
              Authorization: token,
            },
          }
        )
        .then((response: any) => {
          if (response.status === 200) {
            let status = response.status;
            setResponseStatus(status);
            setErrorMessageList([]);
          }
        })
        .catch((error) => {
          let status = error.status;
          setResponseStatus(status);
          setErrorMessageList(error.response.data.errorMessageList);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorMessageList, responseStatus]
  );

  return { deleteBook, errorMessageList, responseStatus };
};
