import { useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { permissionState } from "../store/permissionState";
import { useSetRecoilState } from "recoil";

const BASE_API_URL = process.env.REACT_APP_API_BASE_URL || "";
const AUTH_API_URL = "/api/user/auth";

export const useAuth = () => {
  const navigate = useNavigate();
  const setPermissionData = useSetRecoilState(permissionState);

  const authCheck = useCallback(async (token: string) => {
    await axios
      .get(BASE_API_URL + AUTH_API_URL, {
        headers: {
          Authorization: token,
        },
        data: {},
      })
      .then((response: any) => {
        if (response.status === 200) {
          setPermissionData({ permission: true });
        } else {
          navigate("/");
        }
      })
      .catch((err) => {
        navigate("/");
      });
  }, [navigate, setPermissionData]);
  return { authCheck };
};
