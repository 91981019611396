import { FC, memo, ReactNode } from "react";

type Props = {
  children: ReactNode;
  onClick: () => void;
};

export const TertiaryButton: FC<Props> = memo((props) => {
  const { children, onClick } = props;
  return (
    <button type="button" className="text-geexgreen-400 border border-geexgreen-400 hover:bg-gray-100 rounded px-4 py-2" onClick={onClick}>
      {children}
    </button>
  );
});
