import { ChangeEvent, FC, memo, SetStateAction, useEffect, useState } from "react";
import { EmployeeInfo } from "../../types/api/EmployeeInfo";
import { BookLendReturnForm } from "../../types/form/BookLendReturnForm";
import { TextAreaForm } from "../molecules/TextAreaForm";
import { SelectListForm } from "../molecules/SelectListForm";
import { CalendarTextForm } from "../molecules/CalendarTextForm";
import { SelectItem } from "../../types/SelectItem";

type Props = {
  lendFlag: boolean;
  formData: BookLendReturnForm;
  employeeList: Array<EmployeeInfo>;
  onChangeForm: (e: ChangeEvent<any>) => void;
  onChangeDate?: (e: SetStateAction<Date>) => void;
};

/** 貸出返却モーダルの共通フォーム */
export const LendReturnInputForm: FC<Props> = memo((props) => {
  const { lendFlag, formData, employeeList, onChangeForm, onChangeDate } = props;
  const [selectItemList, setSelectItemList] = useState<Array<SelectItem>>([]);

  useEffect(() => {
    employeeList.map((data) => {
      selectItemList.push({ value: data.employeeNo, label: data.employeeName });
      setSelectItemList(selectItemList);
    });
  }, []);

  return (
    <>
      <div className="mb-6 break-all">書籍名：{formData.bookName}</div>
      {!lendFlag ? (
        <>
          <CalendarTextForm labelText={"貸出日"} name={"lendDate"} onChangeText={onChangeForm} value={formData.lendDate} required={true} onChangeDate={onChangeDate} />
          <SelectListForm labelText={"貸出社員名"} name={"lendEmployeeNo"} selectItem={selectItemList} onChangeItem={onChangeForm} required={true} />
        </>
      ) : (
        <>
          <div className="mb-6">貸出社員名：{formData.employeeFullName}</div>
          <div className="mb-6">貸出日：{formData.lendDate}</div>
          <CalendarTextForm labelText={"返却日"} name={"returnDate"} onChangeText={onChangeForm} value={formData.returnDate} required={true} onChangeDate={onChangeDate} />
        </>
      )}
      <TextAreaForm labelText={"備考"} name={"remarks"} onChangeTextArea={onChangeForm} value={formData.remarks} required={false} />
    </>
  );
});
