import { ChangeEvent, FC, memo } from "react";
import { SelectItem } from "../../types/SelectItem";
import { FormLabel } from "../atoms/FormLabel";
import { RequiredLabel } from "../atoms/RequiredLabel";
import { SelectList } from "../atoms/SelectList";

type Props = {
  labelText: string;
  name: string;
  selectItem: Array<SelectItem>;
  required: boolean;
  onChangeItem: (e: ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectListForm: FC<Props> = memo((props) => {
  const { labelText, name, selectItem, required, onChangeItem } = props;

  return (
    <>
      <div className="mb-6">
        <FormLabel labelText={labelText} />
        <div className="col mb-0">{required && <RequiredLabel labelText={"必須"} />}</div>
        <SelectList name={name} selectItem={selectItem} onChangeItem={onChangeItem} />
      </div>
    </>
  );
});
