import { atom } from "recoil";

type modalInfo = {
  isOpen: boolean;
  isOpenId?: string; // 複数のModal使用時は、表示中のModalのidを保持
};

export const calendarModalInfoState = atom<modalInfo>({
  key: "calendarModalInfoState",
  default: {
    isOpen: false,
  },
});
