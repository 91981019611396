import { ChangeEvent, FC, memo } from "react";
import { SelectItem } from "../../types/SelectItem";

type Props = {
  name: string;
  value?: string;
  isDisabled?: boolean;
  defaultSelectedLabel?: string;
  defaultSelectedValue?: string;
  selectItem: Array<SelectItem>;
  onChangeItem: (e: ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectList: FC<Props> = memo((props) => {
  const { name, value, isDisabled = false, defaultSelectedLabel, defaultSelectedValue, selectItem, onChangeItem } = props;
  return (
    <select onChange={onChangeItem} className="w-full bg-gray-50 text-gray-800 border focus:ring ring-indigo-300 rounded outline-none transition duration-100 px-3 py-2" name={name} value={value} disabled={isDisabled}>
      <option value={defaultSelectedValue ? defaultSelectedValue :""}>{defaultSelectedLabel ? defaultSelectedLabel : "選択してください"}</option>
      {selectItem.map((data, index) => {
        return (
          <option value={data.value} key={index}>
            {data.label}
          </option>
        );
      })}
    </select>
  );
});
