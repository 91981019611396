import { BrowserRouter, Routes, Route } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { BookRegist } from "./components/pages/BookRegist";
import { LoginPage } from "./components/pages/LoginPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BookList } from "./components/pages/BookList";
import { BookBarcode } from "./components/pages/BookBarcode";

export const App = () => {
  return (
    <>
      <RecoilRoot>
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="bookRegist" element={<BookRegist />} />
            <Route path="bookList" element={<BookList />} />
            <Route path="bookBarcode" element={<BookBarcode />} />
          </Routes>
        </BrowserRouter>
      </RecoilRoot>
    </>
  );
};
